<template>
  <div>

    <h1 class="text-2xl font-bold mb-5">Bank Account users</h1>

    <div class="border border-solid border-blue-200 rounded overflow-hidden">
      <datatable
        :ajax="true"
        :ajaxPagination="true"
        :url="users.url"
        :columns="users.columns"
        :loading="users.loading"
        :query="users.query"
        sort="attended_at"
        order="desc"
        :onClick="click"
        ref="table"
      />
    </div>

    <modals-user
      :selected-user="users.selected"
      ref="userModal"
      @delete="userDeleted"
      @close="userModalClosed"
    />

  </div>
</template>
<script>
  import moment from 'moment';

  export default {
    data() {
      return {
        users: this.$options.resource([], {
          url: `${this.$baseurl}/admin/personal/bank-accounts`,
          query: '',
          selected: null,
          columns: [
            {
              name: 'id',
              th: 'User ID',
            },
            {
              name: 'name',
              th: 'User',
              render: user => `${user.name} ${user.last_name}`
            },
            {
              name: 'name_on_account',
              th: 'Name On Account',
              render: user => user.profile?.bank_account_name
            },
            {
              name: 'name_on_bvn',
              th: 'Name on BVN',
              render: user => `${user.profile?.bvn_first_name} ${user.profile?.bvn_last_name}`
            },
            {
              name: 'admin',
              th: 'Admin Responsible',
              render: user => {
                const action = [...user.admin_actions].pop();
                const admin = action.admin;
                return `${admin?.name} ${admin?.last_name}`;
              }
            },
            {
              name: 'action',
              th: 'Status',
              render: user => {
                const action = [...user.admin_actions].pop();
                switch (action.action) {
                  case "verify_bank_account":
                    return '<div class="badge badge-green">Verified</div>';
                  case "refute_bank_account":
                    return '<div class="badge badge-red">Refuted</div>';
                  default:
                    return '<div class="badge badge-gray">Unknown</div>';
                }
              }
            },
            {
              name: 'attended_at',
              th: 'Date Acted On',
              render: user => {
                const action = [...user.admin_actions].pop();
                return moment(action.created_at, '').fromNow();
              }
            }
          ]
        })
      }
    },
    methods: {
      click(user) {
        this.users.selected = user;
        this.$refs.userModal.open();
      },
      reloadTable() {
        this.$refs.table.loadAjaxData()
      },
      userDeleted() {
        this.reloadTable();
      },
      userModalClosed() {
        this.users.selected = null;
      },
    }
  }
</script>
